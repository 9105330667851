module.exports = [{
      plugin: require('/Users/awe/proj/software/practice/gatsby/sexwlo-blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/awe/proj/software/practice/gatsby/sexwlo-blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false},
    },{
      plugin: require('/Users/awe/proj/software/practice/gatsby/sexwlo-blog/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/awe/proj/software/practice/gatsby/sexwlo-blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-10207332-29"},
    }]
